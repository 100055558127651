<template>
    <!-- SOP管理工单查询 -->
    <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
        <el-card>
            <el-form ref="queryForm" :model="queryParams" size="small" label-width="90px" @submit.native.prevent>
                <div class="formModel">
                    <el-form-item label="标题" prop="title">
                        <el-input class="formItem" v-model="queryParams.title" placeholder="请输入标题" clearable
                            @keyup.enter.native="handleQuery" />
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-select v-model="queryParams.status" placeholder="请选择状态" clearable
                            @keyup.enter.native="handleQuery">
                            <el-option v-for="item in statusOptions" :key="item.id" :label="item.value"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间" prop="createdTimeRange">
                        <el-date-picker v-model="createdTimeRange" type="daterange" class="formItem"
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始时间"
                            end-placeholder="结束时间" clearable @keyup.enter.native="handleQuery">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="更新时间" prop="updatedTimeRange">
                        <el-date-picker v-model="updatedTimeRange" type="daterange" class="formItem"
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始时间"
                            end-placeholder="结束时间" clearable @keyup.enter.native="handleQuery">
                        </el-date-picker>
                    </el-form-item>
                    <div class="formItem btns">
                        <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery">搜索
                        </el-button>
                        <el-button icon="el-icon-refresh" size="small" @click="handleFormReset('queryForm')">重置
                        </el-button>
                    </div>
                </div>
            </el-form>
        </el-card>
        <el-card style="margin-top: 15px; padding-bottom: 20px">
            <el-table v-loading="loading" :data="list" style="width: 100%; margin-top: 20px">
                <el-table-column property="title" label="标题"></el-table-column>
                <el-table-column property="description" label="描述"></el-table-column>
                <el-table-column property="created_at" label="创建时间"></el-table-column>
                <el-table-column property="updated_at" label="更新时间"></el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" @click="downloadFile(scope.row.link)">下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="pageParams.page" layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[25, 50, 100]" :page-size.sync="pageParams.limit" :total="total" style="margin-top: 20px"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { sopManageData } from '@/api/sopManage'; // 假设这是您的API请求方法

export default {
    name: 'SOPManageList',
    data() {
        return {
            fullscreenLoading: false,
            loading: false,
            total: 0,
            list: [],
            queryParams: {
                title: '', // 标题
                status: '', // 状态
            },
            pageParams: {
                page: 1,
                limit: 25,
            },
            statusOptions: [
                // 状态数据
                {
                    id: 1,
                    value: '启用',
                },
                {
                    id: 2,
                    value: '禁用',
                },
            ],
            createdTimeRange: [],
            updatedTimeRange: [],
        };
    },
    created() {
        this.getList();
    },
    methods: {
        // 获取列表
        getList() {
            this.loading = true;
            const payload = {
                ...this.pageParams,
                ...this.formattingParams(),
            };
            sopManageData(payload)
                .then((res) => {
                    if (res.data.code === 0) {
                        this.list = res.data.data;
                        this.total = res.data.count;
                        this.loading = false;
                    } else {
                        this.$message.error(res.data.msg);
                        this.loading = false;
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 格式化查询参数
        formattingParams() {
            let params = { ...this.queryParams };
            if (this.createdTimeRange?.length) {
                params.created_at_start = `${this.createdTimeRange[0]} 00:00:00`; // 创建开始时间
                params.created_at_end = `${this.createdTimeRange[1]} 23:59:59`; // 创建结束时间
            }
            if (this.updatedTimeRange?.length) {
                params.updated_at_start = `${this.updatedTimeRange[0]} 00:00:00`; // 更新开始时间
                params.updated_at_end = `${this.updatedTimeRange[1]} 23:59:59`; // 更新结束时间
            }
            return params;
        },
        // 搜索按钮操作
        handleQuery() {
            this.pageParams.page = 1;
            this.pageParams.limit = 25;
            this.getList();
        },
        // 重置查询条件
        handleFormReset(formName) {
            this.$refs[formName].resetFields();
            this.createdTimeRange = [];
            this.updatedTimeRange = [];
            this.handleQuery();
        },
        // 监听每页条数改变
        handleSizeChange(newSize) {
            this.pageParams.limit = newSize;
            this.pageParams.page = 1;
            this.getList();
        },
        // 监听当前页面变化
        handleCurrentChange(newPage) {
            this.pageParams.page = newPage;
            this.getList();
        },
        // 下载文件
        downloadFile(url) {
            if (url) {
                window.open(url, '_blank');
            } else {
                this.$message.warning('文件链接不存在');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .demo-ruleForm .el-form-item {
    margin-bottom: 16px !important;
}

.item-shortcut-statement {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
        padding-right: 20px;
        font-size: 14px;
        line-height: 18px;
        color: #333;
    }
}

.standard-blue-link {
    color: #1890ff;
    text-decoration: none;
}

.formModel {
    display: flex;
    flex-flow: row wrap;
}

.formItem {
    margin-bottom: 20px;
}

::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 0px;
}

::v-deep .el-card__body {
    padding: 15px 20px 0 20px !important;
}

.btns {
    margin-left: 20px;
}
</style>